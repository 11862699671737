import api from "api";
import {
  UserFormObject,
  UserFormSchema,
  UserObject,
  UserRoleTypes,
  allowedRolesForUser,
} from "api/endpoints/users";
import useRequest from "api/use-request";
import { useEffect, useState } from "react";

import { FormFieldVariant } from "components/form/common";
import { Form, FormNotification } from "components/form/form";
import InputField, { InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";

import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import _ from "lodash";
import * as quartzite from "quartzite";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { debug } from "utils/debug";

import { ClientObject } from "api/endpoints/clients";
import { SendResetLinkRequest } from "api/endpoints/password";
import classNames from "classnames";
import { Multiselect } from "components/controls/multiselect-control";
import { Field } from "formik";
import { store } from "store";

import { ReactComponent as IconClose } from 'assets/svg/close.svg';

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IUserDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
  isProfile: boolean;
}

function newUserObj(clientId: string | undefined): UserFormObject {
  return _.clone({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "user",
    new_password: "",
    new_password_confirm: "",
    clients: clientId ? [clientId] : [],
  });
}

export function UserDetails({ isNew, nextLocation, onUpdate, isProfile }: IUserDetailsProps) {
  let [initialValue, setInitialValue] = useState<UserFormObject>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [editedUser, setEditedUser] = useState({} as UserObject);
  let currentUser = useRequest(api.users.getCurrentUser());
  let [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  let allClients = useRequest(api.clients.getClients({ page: 1, limit: 1000 }));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [selectedRole, setSelectedRole] = useState(editedUser.role);

  let validationSchema = UserFormSchema;
/*
  let validationSchema = UserFormSchema.omit({
    id: true,
    profile_image: true,
    profile_image_url: true,
  });
*/

  let history = useHistory();
  let { clientId, userId } = useParams() as {
    clientId: string | undefined;
    userId: string | undefined;
  };
  if (isProfile && currentUser.data) {
    userId = currentUser.data.id.toString();
  }

  useEffect(() => {
    if (initialValue) {
      setSelectedRole(initialValue.role);
    }
  }, [initialValue]);

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: UserFormObject = {
      id: userId ? userId : "",
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
      clients: values.clients,
    };

    let update: Promise<UserObject>;
    if (isNew) {
      update = api.users.newUser(payload).fetch();
    } else {
      update = api.users.updateUser(payload, userId ?? "").fetch();
    }

    return update.then((response) => {
      if (isProfile) {
        store.notifications.presentNotification("Profile changes were saved.", true, "success");
      } else {
        if (isNew) {
          store.notifications.presentNotification("User has been added.", true, "success");
        } else {
          store.notifications.presentNotification("User changes were saved.", true, "success");
        }
      }

      if (isNew) {
        api.users
          .sendWelcomeEmail({
            user_id: response.id,
            return_url: window.location.origin + "/verify-email",
          })
          .fetch();
      }
      onUpdate();

      if (nextLocation !== "") {
        history.replace(nextLocation);
      } else {
        history.goBack();
      }
    });
  };

  const loadUser = async (userId: string) => {
    let user = await api.users.getUser({ id: userId }).fetch();
    if (user) {
      setEditedUser(user);
      let clientsIds = user.clients.map((client) => {
        return client.id;
      });
      setInitialValue({ ...user, clients: clientsIds });
    }
  };

  const initForm = () => {
    if (!userId) {
      if (isProfile && currentUser.data) {
        userId = currentUser.data.id.toString();
        setEditedUser(currentUser.data);
        let clientsIds = currentUser.data.clients.map((client) => {
          return client.id;
        });

        setInitialValue({ ...currentUser.data, clients: clientsIds });
      } else {
        setInitialValue(newUserObj(clientId));
      }
    } else {
      loadUser(userId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");
    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  const shouldShowRolesSelector = () => {
    return (
      currentUser.data &&
      currentUser.data.id !== editedUser?.id &&
      currentUser.data.role !== UserRoleTypes.User &&
      allowedRolesForUser(currentUser.data, editedUser).length > 0
    );
  };

  function deleteEditedUser() {
    if (userId) {
      let update = api.users.deleteUser({ id: userId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          history.push(nextLocation);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  function closeModal() {
    history.goBack();
  }

  function sendResetPasswordEmail() {
    const payload: SendResetLinkRequest = {
      email: editedUser.email,
      reset_url: window.location.origin + "/password/reset",
    };
    api.password
      .sendResetLink(payload)
      .fetch()
      .then((response) => {
        setPasswordResetLinkSent(true);
      });
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper c-modal__wrapper--lg">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            closeModal();
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} user</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize={true}
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-7@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>About</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`first_name`}
                                        placeholder="First Name"
                                        label="First Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`last_name`}
                                        placeholder="Last Name"
                                        label="Last Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    {currentUser.data?.role === UserRoleTypes.Admin ? (
                                      <div className="o-col-12">
                                        <InputField
                                          type={InputFieldType.text}
                                          name={`email`}
                                          placeholder="Email Address"
                                          label="Email Address"
                                          variant={FormFieldVariant.fill}
                                          autocomplete={false}
                                        />
                                      </div>
                                    ) : (
                                      <div className="o-col-12">
                                        <InputField
                                          type={InputFieldType.text}
                                          name={`email`}
                                          placeholder="Email Address"
                                          label="Email Address"
                                          variant={FormFieldVariant.fill}
                                          autocomplete={false}
                                          disabled={true}
                                        />
                                      </div>
                                    )}

                                    {currentUser.data && shouldShowRolesSelector() ? (
                                      <div className="o-col-12">
                                        <label
                                          htmlFor={"role"}
                                          className={classNames("c-form-label")}
                                        >
                                          User Role
                                        </label>
                                        <Field
                                          name="role"
                                          id="role"
                                          component={Multiselect}
                                          placeholder="User Role"
                                          options={allowedRolesForUser(
                                            currentUser.data,
                                            editedUser
                                          ).map((role) => {
                                            return { value: role.role, label: role.title };
                                          })}
                                          isMulti={false}
                                          onChange={(selectedValue: string) => {
                                            setSelectedRole(selectedValue);
                                          }}
                                        />
                                        <br />
                                      </div>
                                    ) : (
                                      <div />
                                    )}

                                    {allClients.data ? (
                                      <div className="o-col-12">
                                        <label
                                          htmlFor={"clients"}
                                          className={classNames("c-form-label")}
                                        >
                                          Clients
                                        </label>
                                        <Field
                                          name="clients"
                                          id="clients"
                                          component={Multiselect}
                                          placeholder="Clients"
                                          options={allClients.data.data.map(
                                            (client: ClientObject) => {
                                              return {
                                                value: client.id,
                                                label: client.name,
                                              };
                                            }
                                          )}
                                          isMulti={true}
                                        />
                                        <br />
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                </div>
                                {!isNew && (
                                  <div className="u-mb-spacer-base-large">
                                    <h6>Change password</h6>
                                    <div className="o-row o-row--small-gutters">
                                      <div className="o-col-12@md o-col-12@lg o-col-12@xl">
                                        {passwordResetLinkSent ? (
                                          <div className="c-alert c-alert--small@xs c-alert--row@md c-alert--success c-color--invert">
                                            <div className="c-alert__col c-alert__body">
                                              <p>
                                                <strong>Password change requested</strong>
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="c-link-cta"
                                            onClick={(event) => {
                                              event.preventDefault();
                                              sendResetPasswordEmail();
                                            }}
                                          >
                                            <span>Request password change</span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                            <div className="c-card c-card--bg-light">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedUser && editedUser.updated_at ? (
                                        <p>
                                          This user was last updated{" "}
                                          {formatDate(editedUser.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton>
                                      <span>{isNew ? "Create" : "Update"} user</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <span>Delete account</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedUser}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedUser.first_name + " " + editedUser.last_name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )}
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper c-modal__wrapper--lg">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline">
                      <Skeleton width={250} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="o-row">
                      <div className="o-col-6@lg o-col-7@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={420} height={60} />
                            </div>
                          </div>
                        </div>
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={200} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={200} height={50} />
                            </div>
                          </div>
                          <p className="c-note">
                            <Skeleton count={2} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-light">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="c-card__desc">
                            <p>
                              <Skeleton count={2} />
                            </p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
